// Dependencies.
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import castArray from 'lodash/castArray';
import AmelieSignInForm from '../AmelieSignInForm/AmelieSignInForm';
import * as authActions from '../../redux/auth';
import * as favoritesActions from '../../redux/favorites';
import * as fieldsActions from '../../redux/fields';
import * as listingActions from '../../redux/listing';
import Utils from '../../utils/utils';

// Define component class.
class AmelieMain extends PureComponent {
  UNSAFE_componentWillMount() {
    const { loadFields, loadListing } = this.props;

    if (Utils.getDefaultConfig('enhancedEnabled')) {
      loadFields();
      loadListing();
    }
  }

  buildRouter(query, doReplace = true) {
    const { location, history } = this.props;
    // Build query string with ordered params.
    // Update the URL.
    const newURL = `${location.pathname}?${query}`;
    if (doReplace) {
      history.replace(newURL);
    } else {
      history.push(newURL);
    }
  }

  removeFromRouter(keysToRemove) {
    const keys = castArray(keysToRemove);

    // Strip the keys from the query string.
    const uri = new URL(window.location.href);
    keys.forEach((key) => {
      uri.searchParams.delete(key);
    });
    // Build query string with ordered params.
    this.buildRouter(uri.searchParams.toString(), false);
  }

  // Render the component.
  render() {
    const { auth, query, hideForm, signIn, signInError, signOut, setFavorite, loadFields,
      loadListing, loadFavorites, getVowTermsOfService } = this.props;

    if (!auth.userToken && query.actionFrom !== 'pslc') {
      return (
        <div>
          <AmelieSignInForm
            auth={auth}
            userToken={query.token}
            favoriteMlsListingID={query.favorite}
            hideForm={hideForm}
            signIn={signIn}
            signInError={signInError}
            signOut={signOut}
            loadFields={loadFields}
            loadListing={loadListing}
            loadFavorites={loadFavorites}
            setFavorite={setFavorite}
            removeFromRouter={(keys) => this.removeFromRouter(keys)}
            getVowTermsOfService={getVowTermsOfService}
          />
        </div>
      );
    }
    return null;
  }
}

// Property validation.
AmelieMain.propTypes = {
  // Properties.
  auth: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,

  // Functions.
  hideForm: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signInError: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  loadFields: PropTypes.func.isRequired,
  loadListing: PropTypes.func.isRequired,
  loadFavorites: PropTypes.func.isRequired,
  setFavorite: PropTypes.func.isRequired,
  getVowTermsOfService: PropTypes.func.isRequired,

  // External guff.
  location: PropTypes.object.isRequired,
};

// Context.
AmelieMain.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = ({ auth }, ownProps) => ({
  auth,
  query: queryString.parse(ownProps.location.search),
});

const mapDispatchToProps = (dispatch) => {
  const actionCreators = {
    ...authActions,
    ...favoritesActions,
    ...fieldsActions,
    ...listingActions,
  };
  return bindActionCreators(actionCreators, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AmelieMain));

// For tests.
export { AmelieMain as PureAmelieMain };

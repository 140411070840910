import React from 'react';
import classNames from 'classnames';
import styles from './Alert.module.scss';

function Alert({ type, message, className }) {
  return (
    <div
      className={classNames(className, styles.alert, {
        [styles.error]: type === 'error',
      })}
    >
      {message}
    </div>
  );
}

export default Alert;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Input, Form, Alert, Icon, Select } from '../AmelieUI';
import AmelieDisclaimer from 'components/AmelieDisclaimer/AmelieDisclaimer';
import Utils from '../../utils/utils';
import './AmelieFABForm.scss';

// Form validation tweak. This prevents displaying validation message on the UI.
const ERROR_MESSAGE = ' ';

export class AmelieFABForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    source: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    additionalNotes: PropTypes.string,
  };

  static defaultProps = {
    additionalNotes: null,
  };

  state = {
    isDone: false,
    isValid: true,
    isError: false,
    isLoading: false,
    visibleComment: true,
  };

  componentDidMount() {
    document.body.classList.add('hide-background-scroll');
  }

  componentWillUnmount() {
    document.body.classList.remove('hide-background-scroll');
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, dispatch, source, additionalNotes } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return this.setState({
          isValid: false,
        });
      }

      let note = '';
      if (values.message) {
        note = values.message;
      }
      if (additionalNotes) {
        if (note) {
          note = `${note}\n${additionalNotes}`;
        } else {
          note = additionalNotes;
        }
      }

      this.setState({
        isLoading: true,
      }, () => {
        const params = {
          ...values,
          message: note,
          source,
        };
        dispatch.leadCapture.sendBubbleFormData(params)
          .then(() => {
            this.setState({
              isDone: true,
              isError: false,
              isLoading: false,
            });
          })
          .catch(() => {
            this.setState({
              isDone: true,
              isError: true,
              isLoading: false,
            });
          });
      });
    });
  };

  handleCommentClick = () => {
    this.setState({
      visibleComment: true,
    });
  };

  renderDone() {
    const { intl, onClose } = this.props;
    const { isError } = this.state;

    return (
      <div className="amelie-fab-form thankyou">
        <div className="amelie-fab-form-message">
          {!isError
            ? intl.formatMessage({ id: 'amelieFAB.message.thankyou' })
            : intl.formatMessage({ id: 'amelieFAB.message.error' })}
        </div>
        <Button className="amelie-fab-form-button" type="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'amelieFAB.form.backtosite' })}
        </Button>
      </div>
    );
  }

  renderForm() {
    const { intl, form, onClose } = this.props;
    const { isValid, isLoading, visibleComment } = this.state;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      size: 'large',
      colon: false,
    };

    const clientName = Utils.getDefaultConfig('clientName') || '';

    const formBody = Utils.getDefaultConfig('leadCaptureBubble.form.body') || intl.formatMessage({ id: 'amelieFAB.message.body' });
    const formCTA = Utils.getDefaultConfig('leadCaptureBubble.form.CTA') || `${intl.formatMessage({ id: 'amelieFAB.message.message' })} ${clientName}`;

    const leadCaptureRequirePhone = Utils.getDefaultConfig('leadCaptureRequirePhone');

    // Note that id="amelie-fab-form" is used to track the form status
    return (
      <Form className="amelie-fab-form" id="amelie-fab-form" onSubmit={this.handleSubmit}>
        <div className="amelie-fab-form-topbar">
          <Icon type="close" onClick={onClose} />
        </div>
        <div className="amelie-fab-form-header">
          <p>
            {formBody}
          </p>
          <p className="amelie-fab-form-header-client">
            {clientName}
          </p>
        </div>
        <div className="amelie-fab-form-body">
          { !isValid && <Alert type="error" className="amelie-fab-form-error" message={intl.formatMessage({ id: 'amelieFAB.form.validation' })} /> }
          <Form.Item>
            <Form.Item
              {...formItemLayout}
              style={{ display: 'inline-block', width: 'calc(50% - 4px)', marginRight: 4, marginBottom: 0 }}
            >
              {getFieldDecorator('first_name', {
                rules: [{ required: true, message: ERROR_MESSAGE }],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'amelieFAB.form.yourFirstName' })}
                />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              style={{ display: 'inline-block', width: 'calc(50% - 4px)', marginLeft: 4, marginBottom: 0 }}
            >
              {getFieldDecorator('last_name', {
                rules: [{ required: true, message: ERROR_MESSAGE }],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'amelieFAB.form.yourLastName' })}
                />
              )}
            </Form.Item>
          </Form.Item>
          <Form.Item {...formItemLayout}>
            {getFieldDecorator('email', {
              rules: [
                { required: true, type: 'email', message: ERROR_MESSAGE },
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'amelieFAB.form.yourEmailAddress' })}
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout}>
            {getFieldDecorator('phone_number', {
              rules: [
                { required: leadCaptureRequirePhone, message: ERROR_MESSAGE },
                { pattern: /^\d{3}-\d{3}-\d{4}$/, message: ERROR_MESSAGE },
              ],
            })(
              <Input.Mask
                placeholder={intl.formatMessage({ id: 'amelieFAB.form.yourPhoneNumber' })}
                mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout}>
            {getFieldDecorator('preferred_time')(
              <Select
                disabled={isLoading}
                placeholder="When can you be contacted?"
                onChange={this.handleCountryChange}
                options={[
                  { value: 'ASAP', label: 'ASAP' },
                  { value: 'Mornings', label: 'Mornings' },
                  { value: 'Afternoons', label: 'Afternoons' },
                  { value: 'Evenings', label: 'Evenings' },
                ]}
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout}>
            {visibleComment ? (
              <>
                {getFieldDecorator('message')(
                  <Input.TextArea
                    className="amelie-fab-form-text-area"
                    placeholder="Add a comment"
                    rows={4}
                  />
                )}
              </>
            ) : (
              <Button
                className="amelie-fab-form-button"
                icon="plus"
                onClick={this.handleCommentClick}
              >
                Add a comment
              </Button>
            )}
          </Form.Item>
        </div>
        <Form.Item {...formItemLayout} className="amelie-fab-form-bottom">
          <Button className="amelie-fab-form-button" loading={isLoading} type="primary" htmlType="submit">
            {formCTA}
          </Button>
          <AmelieDisclaimer />
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { isDone } = this.state;

    return (
      <>
        { isDone ? this.renderDone() : this.renderForm() }
      </>
    );
  }
}

const enhanced = compose(
  Form.create(),
  connect(),
  injectIntl
);

export default enhanced(AmelieFABForm);

// auth.js

import request from 'superagent';
import LOL from '../api/lol';
import Utils from '../utils/utils';

/**
 * Actions.
 */

export const SHOW_FORM = 'rb-amelie/auth/SHOW_FORM';
export const HIDE_FORM = 'rb-amelie/auth/HIDE_FORM';
export const SIGN_IN = 'rb-amelie/auth/SIGN_IN';
export const SIGN_IN_ERROR = 'rb-amelie/auth/SIGN_IN_ERROR';
export const SIGN_OUT = 'rb-amelie/auth/SIGN_OUT';
export const GET_VOW_TOS = 'rb-maps/auth/GET_VOW_TOS';

/**
 * Local variables.
 */

const maxAttempts = 3;
let tosFailures = 0;

/**
 * Reducer.
 */

const initialState = {
  userToken: window.localStorage.getItem('userToken'),
  isDisplayed: false,
  error: null,
  isSettingFavorite: false,
  vowTermsOfService: null,
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_FORM: {
      return {
        ...state,
        isDisplayed: true,
        isSettingFavorite: action.isSettingFavorite,
      };
    }

    case HIDE_FORM: {
      return {
        ...state,
        isDisplayed: false,
        isSettingFavorite: false,
      };
    }

    case SIGN_IN: {
      return {
        ...state,
        userToken: action.userToken,
        error: null,
      };
    }

    case SIGN_IN_ERROR: {
      return {
        ...state,
        userToken: null,
        error: action.error,
      };
    }

    case SIGN_OUT: {
      return {
        ...state,
        userToken: null,
        error: null,
      };
    }

    case GET_VOW_TOS: {
      return {
        ...state,
        vowTermsOfService: action.terms,
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Action creators.
 */

export function showForm(isSettingFavorite = false) {
  return {
    type: SHOW_FORM,
    isSettingFavorite,
  };
}

export function hideForm() {
  return {
    type: HIDE_FORM,
  };
}

export function signIn(userToken) {
  window.localStorage.setItem('userToken', userToken);
  return {
    type: SIGN_IN,
    userToken,
  };
}

export function signInError(error) {
  window.localStorage.removeItem('userToken');
  return {
    type: SIGN_IN_ERROR,
    error,
  };
}

export function signOut() {
  window.localStorage.removeItem('userToken');
  return {
    type: SIGN_OUT,
  };
}

export function getVowTermsOfService() {
  return (dispatch) => {
    if (tosFailures < maxAttempts) {
      const lolURL = LOL.buildVendorURL();

      // Call to LOL API to retrieve VOW Terms of Service.
      return request.get(lolURL)
        .set('Accept', 'application/json')
        .timeout({
          response: 5000,
          deadline: 10000,
        })
        .then((json) => {
          const vendor = Utils.getVendor();
          const { board } = vendor;
          const { body } = json;
          if (body.bundle[board] && body.bundle[board].vowTermsOfService) {
            const terms = body.bundle[board].vowTermsOfService;
            dispatch({
              type: GET_VOW_TOS,
              terms,
            });
          }
          // Success! Clear the failure count.
          tosFailures = 0;
        })
        .catch((error) => {
          tosFailures += 1;
          Utils.debug(error, tosFailures);
          // Goonies never say die!
          dispatch(getVowTermsOfService());
        });
    } else {
      // Too many failures. Log and reset.
      Utils.debug(`Call to LOL API failed after ${maxAttempts} attempts.`);
      tosFailures = 0;
    }
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Button, Input, Form } from '../AmelieUI';
import Utils from '../../utils/utils';
import { getValidationMessage } from '../../utils/leads';
import './AmeliePSLCForm.scss';

class AmeliePSLCForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const { form, onSubmit } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      } // eslint-disable-line

      onSubmit(values);
    });
  };

  render() {
    const { intl, form, isSaving } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      size: 'large',
      colon: false,
    };
    const leadCaptureRequirePhone = Utils.getDefaultConfig('leadCaptureRequirePhone');

    return (
      <div className="amelie-pslc-form">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item {...formItemLayout} className="amelie-pslc-form-item">
            {getFieldDecorator('first_name', {
              rules: [
                {
                  required: true,
                  message: getValidationMessage('first name'),
                },
              ],
            })(
              <Input
                className="amelie-pslc-form-text-input"
                placeholder={intl.formatMessage({
                  id: 'ameliePSLC.form.placeholderFirstName',
                })}
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} className="amelie-pslc-form-item">
            {getFieldDecorator('last_name', {
              rules: [
                {
                  required: true,
                  message: getValidationMessage('last name'),

                },
              ],
            })(
              <Input
                className="amelie-pslc-form-text-input"
                placeholder={intl.formatMessage({
                  id: 'ameliePSLC.form.placeholderLastName',
                })}
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} className="amelie-pslc-form-item">
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: getValidationMessage('email address', false),
                },
              ],
            })(
              <Input
                className="amelie-pslc-form-text-input"
                placeholder={intl.formatMessage({
                  id: 'ameliePSLC.form.placeholderEmailAddress',
                })}
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} className="amelie-pslc-form-item">
            {getFieldDecorator('phone_number', {
              rules: [
                {
                  required: leadCaptureRequirePhone,
                  message: getValidationMessage('phone number'),
                },
                {
                  pattern: /^\d{3}-\d{3}-\d{4}$/,
                  message: getValidationMessage('phone number', false),
                },
              ],
            })(
              <Input.Mask
                className="amelie-pslc-form-text-input"
                placeholder={intl.formatMessage({
                  id: 'ameliePSLC.form.placeholderPhoneNumber',
                })}
                mask={[
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />
            )}
          </Form.Item>
          <Form.Item className="amelie-pslc-form-item-button">
            <Button
              className="amelie-pslc-form-button"
              loading={isSaving}
              type="primary"
              disabled={isSaving}
              htmlType="submit"
            >
              {intl.formatMessage({ id: 'ameliePSLC.form.submit' })}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

AmeliePSLCForm.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isSaving: PropTypes.bool,
};

AmeliePSLCForm.defaultProps = {
  isSaving: false,
};

const enhanced = compose(
  Form.create(),
  injectIntl
);

export default enhanced(AmeliePSLCForm);
